import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import * as S from "./dashboard.style";
import Table from "../../components/Table/Table";
import {
  BuildingInsights_3,
  Currency,
  DeliveryParcel,
  ProgressBarRound,
} from "@carbon/icons-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Dropdown } from "@carbon/react";
import { getColorForPercentage } from "../../styles/helpers/colors";
import { i18n } from "../../translate/i18n";
import {
  getBusiness,
  getClusterValues,
  getMetrics,
  getMetricsales,
  getMetricstock,
  getProjecteds,
  getStockDaysWithPercent,
  getStockValues,
} from "../../services/Dashboard.service";
import { InputSelectData } from "./dashboard.helpers";
import PerformanceChart from "./PerformanceChart/PerformanceChart";
import VelocityChart from "./VelocityChart/VelocityChart";
import CoverageChart from "./CoverageChart/CoverageChart";
import HealthChart from "./HealthChart/HealthChart";
import { language } from "../../consts/language";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const { t } = i18n;

  const [isLoading, setIsLoading] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedTemporada, setSelectedTemporada] = useState(
    InputSelectData()[0]
  );
  const temporadas = InputSelectData();
  const categorias = [{ id: "all", text: t("allFem") }];
  const canais = [{ id: "all", text: t("allMasc") }];

  const velocityNumber = Number(
    defaultValues?.metrics?.sellThrough?.percentage
  );

  const getDefaultInfos = async (id, attempt = 1, maxAttempts = 4) => {
    setIsLoading(true);

    try {
      const values = {
        business: await getBusiness(id),
        metrics: await getMetrics(id),
        metricsales: await getMetricsales(id),
        metricstock: await getMetricstock(id),
        projecteds: await getProjecteds(id),
        stockDaysWithPercent: await getStockDaysWithPercent(id),
        stockValues: await getStockValues(id),
        clusters: await getClusterValues(id),
      };

      setDefaultValues(values);
    } catch (error) {
      console.log("Erro ao buscar dados: ", error.message);

      let jsonError;
      try {
        jsonError = JSON.parse(error.message);
      } catch (parseErr) {
        jsonError = { status: 500, message: "Erro desconhecido" };
      }

      if (jsonError.status === 401) {
        window.location.href = "/";
        return;
      }

      if (attempt < maxAttempts) {
        console.log(`Tentativa ${attempt} falhou. Re-tentando...`);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        return getDefaultInfos(id, attempt + 1, maxAttempts);
      } else {
        console.log(`Todas as ${maxAttempts} tentativas falharam. Abortando.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedTemporada?.id) return;

    getDefaultInfos(selectedTemporada.id);
  }, [selectedTemporada]);

  if (isLoading) {
    return (
      <S.loading>
        <ProgressBarRound className="icon-spin" size={60} color="#41a05f" />
        <span>{t("loading")}...</span>
      </S.loading>
    );
  }

  return (
    <LayoutWithMenu isMenu isCenter={false}>
      <S.title border>
        <h3>{t("opportunities")}</h3>
      </S.title>

      <S.section>
        <S.section className="filterWrapper">
          <div className="filters">
            <span className="sub">{t("seasons")}</span>
            <Dropdown
              id="temporadas"
              type="inline"
              label={t("seasons")}
              items={temporadas}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={selectedTemporada}
              onChange={(item) => {
                setSelectedTemporada(item.selectedItem);
              }}
            />
            <span className="sub">{t("categories")}</span>
            <Dropdown
              id="categorias"
              type="inline"
              label={t("categories")}
              items={categorias}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={categorias[0]}
            />
            <span className="sub">{t("channels")}</span>
            <Dropdown
              id="canais"
              type="inline"
              label={t("channels")}
              items={canais}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={canais[0]}
            />
          </div>
        </S.section>

        <S.section center style={{ marginTop: "15px" }}>
          <Card
            icon={<BuildingInsights_3 size={24} color="#777A7E" />}
            title={t("business")}
            tag={t(defaultValues?.business?.classify.toLowerCase())}
            score={2}
            bigInfo={
              <span
                style={{
                  color: getColorForPercentage(
                    defaultValues?.business?.percentage
                  ),
                }}
              >
                {defaultValues?.business?.percentage}%
              </span>
            }
            parent
          />
        </S.section>

        <S.section center row style={{ alignItems: "flex-start" }}>
          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            {/* Card Vendas */}
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title={t("sales")}
              tag={t(defaultValues?.metricsales?.classify.toLowerCase())}
              score={1}
              bigInfo={
                <span
                  style={{
                    color: getColorForPercentage(
                      defaultValues?.metricsales?.percentage
                    ),
                  }}
                >
                  {defaultValues?.metricsales?.percentage}%
                </span>
              }
              parent
              child
            />

            <S.section center row gap>
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle={t("factor")}
                title={t("performance")}
                tag={t(defaultValues?.metrics?.cluster?.classify.toLowerCase())}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.cluster?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.cluster?.percentage}%
                  </span>
                }
                child
              />
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle={t("factor")}
                title={t("velocity")}
                tag={t(
                  defaultValues?.metrics?.sellThrough?.classify.toLowerCase()
                )}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.sellThrough?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.sellThrough?.percentage}%
                  </span>
                }
                child
              />
            </S.section>
            <S.section center row gap>
              <S.chartCard>
                <S.chartCardTitle>
                  {t("percentShareByCluster")}
                </S.chartCardTitle>
                <S.chartWrapper>
                  <PerformanceChart clusters={defaultValues.clusters} />
                </S.chartWrapper>
              </S.chartCard>
              <S.chartCard>
                <S.chartCardTitle>{t("velocity")}</S.chartCardTitle>
                <S.chartWrapper>
                  <VelocityChart value={velocityNumber} />
                </S.chartWrapper>
              </S.chartCard>
            </S.section>
          </S.section>

          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            {/* Card Estoque */}
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title={t("stock")}
              tag={t(defaultValues?.metricstock?.classify.toLowerCase())}
              score={4}
              bigInfo={
                <span
                  style={{
                    color: getColorForPercentage(
                      defaultValues?.metricstock?.percentage
                    ),
                  }}
                >
                  {defaultValues?.metricstock?.percentage}%
                </span>
              }
              parent
              child
            />

            <S.section center row gap>
              {/* Card Fator - saúde */}
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle={t("factor")}
                title={t("health")}
                tag={t(defaultValues?.metrics?.giro?.classify.toLowerCase())}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.giro?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.giro?.percentage}%
                  </span>
                }
                child
              />

              {/* Card Fator - cobertura */}
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle={t("factor")}
                title={t("coverage")}
                tag={t(defaultValues?.metrics?.age?.classify.toLowerCase())}
                score={5}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.age?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.age?.percentage}%
                  </span>
                }
                child
              />
            </S.section>
            
            <S.section center row gap>
              {/* Card saúde - gráficos */}
              <S.chartCard>
                <S.chartCardTitle>% {t("health")}</S.chartCardTitle>
                <S.chartWrapper>
                  <HealthChart
                    number={defaultValues?.metrics?.giro?.percentage}
                  /> 
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* <img src="healthGraph.jpeg" style={{ width: "85%" }} /> */}
                  </div>
                </S.chartWrapper>
              </S.chartCard>
              <S.chartCard>
                <S.chartCardTitle>{t("coverageInDays")}</S.chartCardTitle>
                <S.chartWrapper>
                  <CoverageChart
                    number={defaultValues?.stockDaysWithPercent?.stockDays}
                  />
                </S.chartWrapper>
              </S.chartCard>
            </S.section>
          </S.section>
        </S.section>
        <S.section>
          <Table
            temporada={selectedTemporada.id}
            metricas={defaultValues?.metrics}
          />
        </S.section>
      </S.section>
    </LayoutWithMenu>
  );
}
