import React, { useContext } from "react";
import {
  DataTable,
  TableContainer,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
} from "@carbon/react";
import { CreatePlanContext } from "../../../../contexts/CreatePlanContext";
import { i18n } from "../../../../translate/i18n";
import { defineCategoryName } from "../../../Otb/utils/otb.components.utils";

import * as S from "../LeadTimeStep.style";

export default function SkuTable({
  getLeadTime,
  defineLeadTime,
  disableInputs,
}) {
  const { t } = i18n;

  const { categories } = useContext(CreatePlanContext);

  const headers = [
    { title: t("category"), key: "name" },
    { title: t("leadTime"), key: "leadTime" },
  ];

  return (
    <DataTable rows={categories} headers={headers}>
      {({ rows, headers, getHeaderProps, getTableProps }) => (
        <TableContainer>
          <S.Table {...getTableProps()} size="md">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    id={header.title}
                    {...getHeaderProps({ header })}
                  >
                    {header.title}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIdx) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      {defineCategoryName(rows[rowIdx].cells[0].value) ||
                        t("category")}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "180px",
                        }}
                      >
                        <TextInput
                          placeholder={t("timeInDays")}
                          type="text"
                          value={getLeadTime(row.id) || ""}
                          onChange={(e) => {
                            const categoryName = row.cells[0].value;
                            defineLeadTime(
                              row.id,
                              categoryName,
                              e.target.value
                            );
                          }}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          disabled={disableInputs}
                        />{" "}
                        {t("days")}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </S.Table>
        </TableContainer>
      )}
    </DataTable>
  );
}
