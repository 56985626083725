import axios from "./BaseService";

export const getOtb = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/otb/`);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar get otb:', err.message);
    throw err;
  }
}

export const simulateOtb = async(inputs) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/otb/simulate`, inputs);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar simulate otb:', err.message);
    throw err;
  }
}

export const updateOtb = async(newData) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/otb`, newData);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar update otb:', err.message);
    throw err;
  }
}

export const getActions = async(language) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/otb/actions/?language=${language}`);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar get actions:', err.message);
    throw err;
  }
}

export const generateSalesCurve = async(periodTarget, selectedMonths) => {
  const payload = {
    periodTarget: periodTarget,
    months: selectedMonths
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/otb/sales-curve`, payload);
    return response.data;
  } catch(err) {
    if(err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      )
    }
  }
}