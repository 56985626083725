import axios from '../services/BaseService'

const getMetrics = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/metrics/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro em getMetrics:', err.message);
    throw err;
  }
};

const getMetricsales = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/metricsales/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }

    console.error('Erro ao buscar metricsales:', error.message);
    throw error;
  }
};

const getMetricstock = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/metricstock/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }

    console.error('Erro ao buscar metricstock:', error.message);
    throw error;
  }
};

const getBusiness = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/business/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }

    console.error('Erro ao buscar business:', error.message);
    throw error;
  }
};

const getProjecteds = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/projecteds/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }

    console.error('Erro ao buscar projecteds:', error.message);
    throw error;
  }
};

const getStockDaysWithPercent = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/stockdayswithPercent/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }

    console.error('Erro ao buscar stockdayswithPercent:', error.message);
    throw error;
  }
};

const getStockValues = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/stockvalues/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        JSON.stringify({
          error: error.response.statusText,
          status: error.response.status,
        })
      );
    }
    console.error('Erro ao buscar stockvalues:', error);
    throw error;
  }
};

const getClusterValues = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/clusterValues/${id}`);
    return response.data;
  } catch(err) {
    if(err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }
    console.error('Erro ao buscar getClusterValues:', err);
    throw err;
  }
};

export {
  getBusiness,
  getMetricsales,
  getProjecteds,
  getMetrics,
  getMetricstock,
  getStockDaysWithPercent,
  getStockValues,
  getClusterValues,
};