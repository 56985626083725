import { language } from "../../../consts/language";

export const defineCategoryName = (name) => {

    const languageCode = language.slice(0, 2);
    
    if (name === "ACESSORIOS") {
      if (languageCode === "en") {
        return "ACCESSORIES";
      } else {
        return "ACESSORIOS";
      }
    }
    if (name === "ROUPAS") {
      if (languageCode === "en") {
        return "CLOTHES";
      } else {
        return "ROUPAS";
      }
    }
    if (name === "PRAIA") {
      if (languageCode === "en") {
        return "BEACHWEAR";
      } else {
        return "PRAIA";
      }
    }
    return null;
};

export const displayLastYearValue = (categoryData, field, formatter) => {
  const categoryArray = Array.isArray(categoryData) ? categoryData : [categoryData];

  const lastYearDate = getLastYearDate(categoryArray[0]?.mes || '');
  const lastYearObj = categoryArray.find((entry) => entry.mes === lastYearDate);

  if (lastYearObj && lastYearObj[field] !== undefined) {
    return formatter(lastYearObj[field]);
  } else {
    return formatter(0);
  }
};

const generateFutureMonthsData = (category, startYear, endYear) => {
  const data = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 1; month <= 12; month++) {
      data.push({
        id: data.length,
        categoria: category,
        mes: getDateString(`${month}-2026`),
        year: year,
        carteira: 0,
        cobertura: 0,
        createdAt: new Date(),
        estoqueFinal: 0,
        estoqueIdeal: 0,
        giro: "0",
        metaVenda: "0",
        otbDisponivel: 0,
        perdaPotencial: "0",
        precoMedio: "0",
        projecaoRecebimento: 0,
        saldoEstoque: null,
        shareVenda: "0",
        updatedAt: new Date(),
        vendaPcs: 0,
        vendaRs: "0"
      });
    }
  }
  return data;
};

const getLastValidMonth = (filteredData) => {
  if (filteredData.length === 0) return null;
  
  return filteredData
    .filter(item => item.mes)
    .sort((a, b) => new Date(a.mes) - new Date(b.mes))
    .pop().mes;
};

const generateAdditionalMonths = (
  category, 
  lastExistingMonth, 
  firstPlannedMonth, 
  startYear, 
  endYear
) => {
  const data = [];
  
  if (!lastExistingMonth) return generateFutureMonthsData(category, startYear, endYear);

  const lastExistingDate = new Date(lastExistingMonth);
  const firstPlannedDate = new Date(firstPlannedMonth);

  let currentDate = new Date(lastExistingDate);
  currentDate.setMonth(currentDate.getMonth() + 1);

  while (currentDate > firstPlannedDate) {
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    
    data.push({
      id: data.length,
      categoria: category,
      mes: getDateString(`${currentMonth}-${currentYear}`),
      year: currentYear,
      carteira: 0,
      cobertura: 0,
      createdAt: new Date(),
      estoqueFinal: 0,
      estoqueIdeal: 0,
      giro: "0",
      metaVenda: "0",
      otbDisponivel: 0,
      perdaPotencial: "0",
      precoMedio: "0",
      projecaoRecebimento: 0,
      saldoEstoque: null,
      shareVenda: "0",
      updatedAt: new Date(),
      vendaPcs: 0,
      vendaRs: "0"
    });

    currentDate.setMonth(currentDate.getMonth() + 1);
    if (currentDate.getFullYear() > endYear) {
      break;
    }
  }

  // Generate additional months for future planning if needed
  const additionalFutureData = generateFutureMonthsData(
    category, 
    startYear, 
    endYear
  );

  return [...data, ...additionalFutureData];
};

export const getCategoryData = (futureMonths, allMonthsCategoryData, category, previousMonthsCount = 18) => {
  const planningMonthsCount = futureMonths.length;
  const firstPlannedMonth = getDateString(futureMonths[0]);

  const filteredData = allMonthsCategoryData.filter(entry => entry.categoria === category);
  const uniqueDataMap = new Map(filteredData.map(item => [item.mes.trim(), item]));

  const lastExistingMonth = getLastValidMonth(filteredData);

  const startYearForGeneration = new Date(firstPlannedMonth).getFullYear();
  const endYearForGeneration = futureMonths[futureMonths.length - 1].split("-")[1];
  const generatedData = generateAdditionalMonths(
    category, 
    lastExistingMonth, 
    firstPlannedMonth, 
    startYearForGeneration, 
    endYearForGeneration
  );

  generatedData.forEach(item => uniqueDataMap.set(item.mes.trim(), item));
  const uniqueData = Array.from(uniqueDataMap.values())
    .sort((a, b) => new Date(a.mes) - new Date(b.mes));

  const firstPlannedMonthIndex = uniqueData.findIndex((entry) => entry.mes === firstPlannedMonth);
  if (firstPlannedMonthIndex === -1) return [];
  let firstMonthToShowIndex = Math.max(0, firstPlannedMonthIndex - previousMonthsCount);
  if (firstPlannedMonthIndex - firstMonthToShowIndex < previousMonthsCount) firstMonthToShowIndex = 0;
  const lastMonthToShowIndex = firstPlannedMonthIndex + planningMonthsCount;

  const result = uniqueData.slice(firstMonthToShowIndex, lastMonthToShowIndex);
  return result;
};

export const getDateString = (jsDate) => {
    const date = jsDate.split("-");
    const month = date[0].padStart(2, "0");
    const year = date[1];
    return `${year}-${month}-01`;
};

export const getLastYearDate = (date) => {
  const originalDate = date.split("-");
  const month = String(originalDate[1]).padStart(2, "0");
  return `${originalDate[0] - 1}-${month}-01`;
};

export const getMonthName = (date) => {
  if (!date) return null;

  const [year, month, day] = date.split("T")[0].split("-").map(Number);
  const dateObj = new Date(year, month - 1, day);
  const monthName = dateObj.toLocaleString(language, { month: "long" });
  const yearSuffix = year.toString().slice(-2);

  return monthName.charAt(0).toUpperCase() + monthName.slice(1) + "/" + yearSuffix;
};

const getOnlyDigits = (input) => {
  if (!input) return 0;
  const normalizedInput = input.replace(",", ".").replace(/[^\d.]/g, "");
  return normalizedInput;
}

export const growthPercent = (value, lastYearValue) => {
  const firstValue = typeof value === "string" ? getOnlyDigits(value) : value;
  const secondValue =
    typeof lastYearValue === "string"
      ? getOnlyDigits(lastYearValue)
      : lastYearValue;

  if (!firstValue || !secondValue) {
    return "";
  }
  const result = (firstValue / secondValue - 1) * 100;

  return `${Number(result).toLocaleString(language, {
    maximumFractionDigits: 1,
  })} %`;
};
  
export const toCurrency = (value, decimals, language) => {
    return Number(value).toLocaleString(language, {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: decimals,
    });
};