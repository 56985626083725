import React, { useContext, useState } from "react";
import { Checkbox, Column, Grid, TextInput } from "@carbon/react";
import {
  ArrowRight,
  ArrowLeft,
  DeliveryParcel,
  Mobile,
  Store,
} from "@carbon/icons-react";
import { i18n } from "../../../translate/i18n";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import PropTypes from 'prop-types';
import Icon from "../../../assets/icons/Shopping--cart.svg";
import * as S from "./SalesChannelStep.style";

export default function SalesChannelStep({ onCompleteStep, onBackStep }) {
  const { t } = i18n;
  const { channels, setChannels } = useContext(CreatePlanContext);
  const [isChecked, setIsChecked] = useState(false);
  const checkOptions = {
    fisica: false,
    digital: false,
    atacado: false,
  };
  const [selectedChecks, setSelectedChecks] = useState(checkOptions);

  const somaMetas = Object.values(channels).reduce((curr, acc) => {
    if (!isNaN(curr)) return Number(curr) + Number(acc);
    else return Number(acc);
  }, 0);

  const anyChannelSelected = Object.values(selectedChecks).some(
    (check) => check
  );

  const noChannelUnchecked = !isChecked && !anyChannelSelected;
  const sumError = !isChecked && somaMetas !== 100;

  const disableNext = () => {
    return noChannelUnchecked || sumError;
  };

  const showSumError = anyChannelSelected && somaMetas !== 0 && sumError;

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt={t("salesChannels")} />
          <h4>{t("salesChannels")}</h4>
        </S.Title>
        <S.Text>{t("salesChannelsExplanation")}</S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text>{t("listSalesChannels")}</S.Text>
        <S.Check>
          <Checkbox
            id="check"
            labelText={t("dontSeparateChannels")}
            checked={isChecked}
            onChange={(_, { checked }) => {
              setIsChecked(checked);
              setSelectedChecks(checkOptions);
            }}
          />
        </S.Check>
        <S.List>
          <Checkbox
            id="checkStore"
            disabled={isChecked}
            checked={!isChecked && selectedChecks.fisica}
            onChange={(_, { checked }) => {
              setSelectedChecks({
                ...selectedChecks,
                fisica: !selectedChecks.fisica,
              });
              setChannels({ ...channels, fisica: "" });
            }}
            labelText={
              <>
                <Store /> {t("physicalStore")}
              </>
            }
          />
          <S.Input>
            <TextInput
              disabled={!selectedChecks.fisica}
              max={100}
              value={channels.fisica}
              placeholder={t("goalParticipation")}
              onChange={(e) => {
                setChannels({ ...channels, fisica: e.target.value });
              }}
            />
            {channels.fisica !== 0 ? <p>%</p> : <></>}
          </S.Input>
          <Checkbox
            id="checkDigital"
            disabled={isChecked}
            checked={!isChecked && selectedChecks.digital}
            onChange={(_, { checked }) => {
              setSelectedChecks({
                ...selectedChecks,
                digital: !selectedChecks.digital,
              });
              setChannels({ ...channels, digital: "" });
            }}
            labelText={
              <>
                <Mobile /> {t("digital")}
              </>
            }
          />
          <S.Input>
            <TextInput
              disabled={!selectedChecks.digital}
              type="percent"
              max={100}
              value={channels.digital}
              placeholder={t("goalParticipation")}
              onChange={(e) =>
                setChannels({ ...channels, digital: e.target.value })
              }
            />
            {channels.digital !== 0 ? <p>%</p> : <></>}
          </S.Input>
          <Checkbox
            id="checkAtacado"
            disabled={isChecked}
            checked={!isChecked && selectedChecks.atacado}
            onChange={(_, { checked }) => {
              setSelectedChecks({
                ...selectedChecks,
                atacado: !selectedChecks.atacado,
              });
              setChannels({ ...channels, atacado: "" });
            }}
            labelText={
              <>
                <DeliveryParcel /> {t("wholesale")}
              </>
            }
          />
          <S.Input>
            <TextInput
              disabled={!selectedChecks.atacado}
              type="percent"
              max={100}
              value={channels.atacado}
              placeholder={t("goalParticipation")}
              onChange={(e) =>
                setChannels({ ...channels, atacado: e.target.value })
              }
            />
            {channels.atacado !== 0 ? <p>%</p> : <></>}
          </S.Input>
        </S.List>
        {showSumError && (
          <S.Text color="red">{t("adjustParticipationSum")}</S.Text>
        )}
        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(1);
            }}
          >
            <ArrowLeft /> {t("back")}
          </S.Button>
          <S.Button
            primary
            disabled={disableNext()}
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(1);
            }}
          >
            {t("next")}
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}

SalesChannelStep.propTypes = {
  onBackStep: PropTypes.func.isRequired,
  onCompleteStep: PropTypes.func.isRequired
}