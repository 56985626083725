import React, { useState, useRef, useEffect } from "react";
import { Toggle, Loading } from "@carbon/react";
import { i18n } from "../../../translate/i18n";
import PropTypes from 'prop-types';
import { language } from "../../../consts/language";
import * as S from "./BusinessView.style";
import { 
  getCategoryData, 
  getMonthName, 
  getLastYearDate,
  growthPercent,
  displayLastYearValue,
  toCurrency
 } from "../utils/otb.components.utils";

import EditPlan from "./EditPlan/EditPlan";

export default function BusinessView({
  otbData,
  planMonths,
  futureMonths,
  setOtbData,
}) {
  const { t } = i18n;
  const [showLastYear, setShowLastYear] = useState(false);

  const scrollableDivRef = useRef(null);
  const dataTableRef = useRef(null);

  const categoryData = getCategoryData(futureMonths, otbData, otbData[0].categoria);
  const flatOtbData = otbData.flatMap((category) => category);

  const displayLackOrExcess = (date, type) => {
    const monthObjs = flatOtbData.filter((entry) => entry.mes === date);
    const sum = monthObjs.reduce((acc, curr) => {
      if (type === "lack" && curr.otbDisponivel > 0) return acc + Number(curr.otbDisponivel);
      if (type === "excess" && curr.otbDisponivel < 0) return acc + Number(curr.otbDisponivel);
      return acc;
    }, 0);
    return sum ?? "";
  };

  const getValue = (date, property, lastYear) => {
    let monthObjs = [];

    if (lastYear) {
      const lastYearDate = getLastYearDate(date.mes);
      monthObjs = flatOtbData.filter((entry) => entry.mes === lastYearDate);
    } else {
      monthObjs = flatOtbData.filter((entry) => entry.mes === date);
    }

    const sum = monthObjs.reduce((acc, curr) => {
      if (curr[property]) return acc + Number(curr[property]);
      else return acc;
    }, 0);
    return sum ? Number(sum) : "";
  };

  const displaySalesTarget = (date, lastYear = null) => {
    if (lastYear) return displayLastYearValue(flatOtbData, "vendaRs", (value) => toCurrency(value, 0));
    const lastElement = categoryData[categoryData.length - 1];
    const monthObjs = flatOtbData.filter((entry) => entry.mes === date && entry.mes !== lastElement.mes);
    const sum = monthObjs.reduce((acc, curr) => {
      return acc + Number(curr.metaVenda ? curr.metaVenda : 0);
    }, 0);
    return toCurrency(sum, 0);
  };

  useEffect(() => {
    if (dataTableRef.current) {
      const tableWidth = dataTableRef.current.offsetWidth;
      scrollableDivRef.current.scrollTo({ left: tableWidth });
    }
  }, []);

  return futureMonths.length ? (
    <S.OuterContainer>
      <S.Container>
        <h1>{t("companyStockHealth")}</h1>
        <Toggle
          labelA={t("compareLY")}
          labelB={t("compareLY")}
          className="toggle"
          size="sm"
          toggled={showLastYear}
          onToggle={() => setShowLastYear(!showLastYear)}
        />

        <S.TablesBox>
          <S.Table paddingBottom="10px">
            <thead>
              <tr>
                <th />
              </tr>
            </thead>

            <tbody>
              <tr>
                <th className="otb-cell">{t("excess")}</th>
              </tr>
              <tr>
                <th className="otb-cell">{t("lack")}</th>
              </tr>
              <tr>
              <th>{t("salesTarget")}</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>{t("currencySales") + " " + t("LY")}</th>
                </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                )}
              <tr>
              <th>{t("unitSales")}</th>
            </tr>
              {showLastYear && (
                <>
                  <tr className="light-green">
                    <th>{t("unitSales") + t("LY")}</th>
                  </tr>
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                </>
              )}
              <tr>
                <th>{t("coverage")}</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>{t("coverage") + t("LY")}</th>
                </tr>
              )}
            </tbody>
          </S.Table>

          <S.BodyTable className="scrollable-div" ref={scrollableDivRef}>
            <S.Table ref={dataTableRef}>
              <thead>
                <tr>
                  {categoryData.map((entry) => (
                    <th key={entry.mes}>{getMonthName(entry.mes)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
               
                {/* Falta */}
                <tr>
                  {categoryData.map((entry) => (
                    <td key={entry.id}>
                      {displayLackOrExcess(entry.mes, "lack") > 0 ? (
                        <S.Tag isOver>
                          <div>{t("lack")}</div>-{" "}
                          {Math.abs(displayLackOrExcess(entry.mes, "lack")).toLocaleString(
                            language,
                            { maximumFractionDigits: 0 }
                          )}
                        </S.Tag>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>

                {/* Excesso */}
                <tr>
                  {categoryData.map((entry) => (
                    <td key={entry.id}>
                      {displayLackOrExcess(entry.mes, "excess") < 0 ? (
                        <S.Tag>
                          <div>{t("excess")}</div>{" "}
                          {Math.abs(displayLackOrExcess(entry.mes, "excess")).toLocaleString(
                            language,
                            { maximumFractionDigits: 0 }
                          )}
                        </S.Tag>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>

                {/* Meta de venda */}
                <tr>
                  {categoryData.map((entry) => (
                    <td key={entry.id}>{displaySalesTarget(entry.mes)}</td>
                  ))}
                </tr> 

                {/* Venda em Peças */}
                <tr>
                  {categoryData.map((entry) => (
                    <td key={entry.mes} className="other-cell">
                      {getValue(entry.mes, "vendaPcs").toLocaleString(
                        language,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}
                    </td>
                  ))}
                </tr>

                {/* Venda em peças LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((entry) => (
                      <td key={entry.mes}>
                        {getValue(entry, "vendaPcs", "lastYear").toLocaleString(
                          language,
                          { maximumFractionDigits: 0 }
                        )}
                      </td>
                    ))}
                  </tr>
                )}

                {showLastYear && (
                  <tr className="lighter-green">
                    {categoryData.map((entry) => (
                      <td key={entry.mes}>
                        {growthPercent(
                          getValue(entry.mes, "vendaPcs"),
                          getValue(entry, "vendaPcs", "lastYear")
                        )}
                      </td>
                    ))}
                  </tr>
                )}

                {/* Cobertura */}
                <tr>
                  {categoryData.map((entry) => (
                    <td key={entry.mes} className="other-cell">
                      {getValue(entry.mes, "cobertura").toLocaleString(
                        language,
                        { maximumFractionDigits: 0 }
                      )}
                    </td>
                  ))}
                </tr>

                {/* Cobertura LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((entry) => (
                      <td key={entry.mes}>
                        {getValue(
                          entry,
                          "cobertura",
                          "lastYear"
                        ).toLocaleString(language, {
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                  </tr>
                )}
              </tbody>
            </S.Table>
          </S.BodyTable>
        </S.TablesBox>
      </S.Container>

      <S.Container>
        <h1>{t("categoryStockHealth")}</h1>
        {planMonths.length ? (
          <EditPlan
            otbData={otbData}
            futureMonths={futureMonths}
            planMonths={planMonths}
            setOtbData={setOtbData}
          />
        ) : (
          <></>
        )}
      </S.Container>
    </S.OuterContainer>
  ) : (
    <S.LoadingWrapper>
      <Loading withOverlay={false} />
    </S.LoadingWrapper>
  );
}

BusinessView.propTypes = {
  otbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      categoria: PropTypes.string.isRequired,
      mes: PropTypes.string.isRequired,
      shareVenda: PropTypes.string.isRequired,
      vendaRs: PropTypes.string.isRequired,
      vendaPcs: PropTypes.number.isRequired,
      otbDisponivel: PropTypes.number.isRequired,
      carteira: PropTypes.number.isRequired,
      estoqueFinal: PropTypes.number.isRequired,
      estoqueIdeal: PropTypes.number.isRequired,
      saldoEstoque: PropTypes.number,
      metaVenda: PropTypes.number,
      precoMedio: PropTypes.string.isRequired,
      projecaoRecebimento: PropTypes.number,
      cobertura: PropTypes.number.isRequired,
      giro: PropTypes.string.isRequired,
      perdaPotencial: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired
    })),
  setOtbData: PropTypes.func,
  futureMonths: PropTypes.arrayOf(PropTypes.string),
  planMonths: PropTypes.arrayOf(PropTypes.string),
}