
const convertDateFormat = (dateString) => {
    const date = new Date(dateString + 'T00:00:00Z');
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const result = `${month}-${year}`;
    return result;
};
  
export const filterFutureMonthsObjects = (originalArray, futureMonths) => {
    return originalArray.filter(obj => {
        const convertedDate = convertDateFormat(obj.mes);
        return futureMonths.includes(convertedDate);
    });
};

export const calculateStockValue = (objects, condition) => {
    return objects.reduce((acc, curr) => {
      if (condition(curr.otbDisponivel)) {
        return acc + curr.otbDisponivel;
      }
      return acc;
    }, 0);
};