import React from "react";
import { i18n } from "../../../../translate/i18n";
import PropTypes from 'prop-types';
import * as S from "./PeriodSection.style";

export default function PeriodSection({ periodOptions, period, setPeriod }) {
  const { t } = i18n;

  return (
    <S.Container>
      <h4>{t("period")}</h4>

      <S.SeasonSet>
        {Object.entries(periodOptions).map((option) => (
          <S.ButtonSeason
          key={periodOptions}
            primary
            className={period === option[0] ? "selected" : ""}
            onClick={() => {
              setPeriod(option[0]);
            }}
          >
            {option[1]}
          </S.ButtonSeason>
        ))}
      </S.SeasonSet>
    </S.Container>
  );
}

PeriodSection.propTypes = {
  periodOptions: PropTypes.shape({
    custom: PropTypes.string,
    eony: PropTypes.string,
    eoy: PropTypes.string,
    next6months: PropTypes.string,
    next12months: PropTypes.string
  }).isRequired,
  period: PropTypes.string.isRequired,
  setPeriod: PropTypes.func.isRequired
}