import React, { useEffect, useState, useMemo } from "react";
import { SelectItem } from "@carbon/react";
import { i18n } from "../../../../translate/i18n";
import * as S from "./EditPlan.style";
import PropTypes from 'prop-types';
import PlanModal from "../../GeneralView/ItemView/PlanModal/PlanModal";
import { defineCategoryName } from "../../utils/otb.components.utils";

export default function EditPlan({
  otbData,
  futureMonths,
  planMonths,
  setOtbData,
}) {
  const { t } = i18n;
  const [selectedCategory, setSelectedCategory] = useState("");
  
  const categories = useMemo(() => {
    const uniqueCategories = [];
    otbData.forEach((entry) => {
      const category = entry.categoria;
      if (!uniqueCategories.includes(category)) {
        uniqueCategories.push(category);
      }
    });
    return uniqueCategories;
  }, [otbData]);

  const categoryData = otbData.filter((item) => item.categoria === selectedCategory);

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [planMonths, categories]);

  return (
    <>
      <S.Container>
        <S.Select
          labelText={t("selectCategory")}
          value={selectedCategory}
          onChange={handleChange}
        >
          {categories.map((category) => (
            <SelectItem 
              key={category}
              text={defineCategoryName(category)} 
              value={category} 
            />
          ))}
        </S.Select>
      </S.Container>

      {selectedCategory && categoryData.length > 0 ? (
        <PlanModal
          allMonthsCategoryData={categoryData}
          futureMonths={futureMonths}
          setOtbData={setOtbData}
        />
      ) : null}
    </>
  );
}

EditPlan.propTypes = {
  otbData: PropTypes.arrayOf(PropTypes.shape({
    carteira: PropTypes.number,
    categoria: PropTypes.string,
    cobertura: PropTypes.number,
    createdAt: PropTypes.string,
    estoqueFinal: PropTypes.number,
    estoqueIdeal: PropTypes.number,
    giro: PropTypes.string,
    id: PropTypes.number,
    mes: PropTypes.string,
    perdaPotencial: PropTypes.string,
    precoMedio: PropTypes.string,
    projecaoRecebimento: PropTypes.any,
    saldoEstoque: PropTypes.any,
    shareVenda: PropTypes.string,
    updatedAt: PropTypes.string,
    vendaPcs: PropTypes.number,
    vendaRs: PropTypes.string
  })).isRequired,
  futureMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
  planMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOtbData: PropTypes.func.isRequired
};