import React from "react";
import { i18n } from "../../../../translate/i18n";
import PropTypes from 'prop-types';
import * as S from "./IndexesSection.style";
import StockTag from "../StockTag/StockTag";
import { language } from "../../../../consts/language";
import { 
  filterFutureMonthsObjects,
  calculateStockValue
 } from "./utils/indexes.section.utils";

export default function IndexesSection({ otbData, futureMonths, actions }) {
  const { t } = i18n;

  const futureMonthsObjs = filterFutureMonthsObjects(otbData, futureMonths);
  const stockLack = calculateStockValue(futureMonthsObjs, (value) => value > 0);
  const overStock = calculateStockValue(futureMonthsObjs, (value) => value < 0);
  const potentialLoss = futureMonthsObjs.reduce((acc, curr) => {
    const otbDisponivel = Number(curr.otbDisponivel);
    const giro = Number(curr.giro);
  
    if (!isNaN(otbDisponivel) && !isNaN(giro) && giro !== 0) {
      return acc + ((100 * otbDisponivel) / (2 * giro));
    } else {
      console.warn('Ignorando mês com dados inválidos:', curr);
      return acc;
    }
  }, 0);

  const sumActions = () => {
    let actionsCount = 0;
    actions.forEach((action) => (actionsCount += action.actions.length));
    return actionsCount;
  };

  return (
    <S.Container>
      <div>
        <S.Title>{t("currentStockSituation")}</S.Title>
        <S.StockSection>
          <S.Box twoRows>
            <StockTag />
            <S.Number>
              -{" "}
              {stockLack.toLocaleString(language, { maximumFractionDigits: 0 })}
            </S.Number>
          </S.Box>
          <S.Box twoRows>
            <StockTag isOver />
            <S.Number>
              {Math.abs(overStock).toLocaleString(language, {
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </S.StockSection>
      </div>
      <S.RightContainer>
        <div>
          <S.Title>{t("totalOpenActions")}</S.Title>
          <S.Box>
            <S.Number>{sumActions()}</S.Number>
          </S.Box>
        </div>
        <div>
          <S.Title>{t("totalROIInActions")}</S.Title>
          <S.Box>
            <S.Number>
              {potentialLoss.toLocaleString(language, {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </div>
        <div>
          <S.Title>{t("generalStatus")}</S.Title>
          <S.Box>
            <S.Number>12% / 100%</S.Number>
          </S.Box>
        </div>
      </S.RightContainer>
    </S.Container>
  );
}

IndexesSection.propTypes = {
  otbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      categoria: PropTypes.string.isRequired,
      mes: PropTypes.string.isRequired,
      shareVenda: PropTypes.string.isRequired,
      vendaRs: PropTypes.string.isRequired,
      vendaPcs: PropTypes.number.isRequired,
      otbDisponivel: PropTypes.number.isRequired,
      carteira: PropTypes.number.isRequired,
      estoqueFinal: PropTypes.number.isRequired,
      estoqueIdeal: PropTypes.number.isRequired,
      saldoEstoque: PropTypes.number,
      metaVenda: PropTypes.number,
      precoMedio: PropTypes.string.isRequired,
      projecaoRecebimento: PropTypes.number,
      cobertura: PropTypes.number.isRequired,
      giro: PropTypes.string.isRequired,
      perdaPotencial: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      actions: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  futureMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
};