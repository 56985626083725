import React, { useContext, useEffect, useState, useCallback } from "react";
import { Column, Tabs, Tab, TabList, TabPanels } from "@carbon/react";
import { getActions, getOtb } from "../../services/Otb.service";
import { getLatestPlan, getStatus } from "../../services/PlanningService";
import { CreatePlanContext } from "../../contexts/CreatePlanContext";
import { i18n } from "../../translate/i18n";

import * as S from "./Otb.style";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import GeneralView from "./GeneralView/GeneralView";
import FinalResults from "./FinalResults/FinalResults";
import BusinessView from "./BusinessView/BusinessView";
import { language } from "../../consts/language";

export default function Otb() {
  const { t } = i18n;

  const {
    otbData: previewOtbData,
    operationId,
    setOperationId,
    actions: previewActions,
    selectedMonths
  } = useContext(CreatePlanContext);
  
  const [index, setIndex] = useState(0);
  const [otbData, setOtbData] = useState([]);
  const [plan, setPlan] = useState({});
  const [actions, setActions] = useState([]);

  const getOtbData = async () => {
    const fetchedOtbData = await getOtb();
    setOtbData(fetchedOtbData);
  };

  const checkOperationStatus = useCallback(() => {
    return async () => {
      try {
        const statusData = await getStatus(operationId);
  
        if (statusData.status === "completed") {
          clearInterval();
          setOperationId("completed");
        }
      } catch (err) {
        console.error(err);
        clearInterval();
      }
    };
  }, [operationId, setOperationId]); 

  const getAllActions = async () => {
    const fetchedActions = await getActions(language);
    setActions(fetchedActions);
  };

  useEffect(() => {
    if (previewOtbData.length) {
      setOtbData(previewOtbData);
  
      const checkStatus = checkOperationStatus();
      checkStatus();
  
      setActions(previewActions);
    } else {
      getOtbData();
      getAllActions();
    }
  
    const getLatestPlanData = async () => {
      const fetchedPlan = await getLatestPlan();
      setPlan(fetchedPlan);
    };
    getLatestPlanData();
  }, [checkOperationStatus, previewActions, previewOtbData]);

  const definePlanDates = (startDate, endDate) => {
    if (!startDate || !endDate) return [];
  
    try {
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      if (isNaN(start.getTime()) || isNaN(end.getTime())) return [];

      const monthsBetween =
        (end.getFullYear() - start.getFullYear()) * 12 +
        (end.getMonth() - start.getMonth());
  
      if (monthsBetween < 0 || monthsBetween > 120) return [];
  
      return Array.from({ length: monthsBetween + 1 }).map((_, i) => {
        const date = new Date(start.getFullYear(), start.getMonth() + i);
        return date.toISOString().slice(0, 10);
      });
    } catch (error) {
      console.error('Erro ao definir datas do plano:', error);
      return [];
    }
  };
  
  const planMonths = definePlanDates(plan?.startDate, plan?.endDate);
  const futureMonths = selectedMonths;

  const displayDate = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat(language, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  }

  const generalViewProps = {
    otbData,
    setOtbData,
    futureMonths,
    actions,
    getAllActions,
  };

  const businessViewProps = {
    otbData,
    planMonths,
    futureMonths,
    setOtbData
  };

  return (
    <LayoutWithMenu isCenter={false}>
      <Column lg={16} md={8} sm={4}>
        <S.Section>
          <S.Title>
            <h3>{t("planning")}</h3>
            {plan.startDate ? (
              <p>
                {displayDate(plan.startDate)} - {displayDate(plan.endDate)}
              </p>
            ) : (
              <></>
            )}
          </S.Title>
  
          <Tabs
            selectedIndex={index}
            onChange={({ selectedIndex }) => setIndex(selectedIndex)}
          >
            <TabList fullWidth contained>
              <Tab>{t("planOverview")}</Tab>
              <Tab>{t("businessView")}</Tab>
              {/* <Tab>{t("Resultado Geral")}</Tab> */}
            </TabList>
  
            <TabPanels>
              <S.TabPanel>
                <GeneralView {...generalViewProps} />
              </S.TabPanel>
              <S.TabPanel>
                {otbData.length ? (
                  <BusinessView {...businessViewProps} />
                ) : (
                  <></>
                )}
              </S.TabPanel>
              <S.TabPanel>
                <FinalResults />
              </S.TabPanel>
            </TabPanels>
          </Tabs>
        </S.Section>
      </Column>
    </LayoutWithMenu>
  );
}