import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Calculator, Save, Undo, Edit } from "@carbon/icons-react";
import { Toggle, Modal, Loading } from "@carbon/react";
import {
  simulateOtb,
  updateOtb,
} from "../../../../../services/Otb.service";
import { CreatePlanContext } from "../../../../../contexts/CreatePlanContext";
import { i18n } from "../../../../../translate/i18n";

import * as S from "./PlanModal.style";
import PropTypes from 'prop-types';
import { 
  defineCategoryName, 
  getDateString, 
  toCurrency,
  getCategoryData,
  getMonthName,
  displayLastYearValue,
  growthPercent
} from "../../../utils/otb.components.utils";
import { language } from "../../../../../consts/language";

export default function PlanModal({
  allMonthsCategoryData,
  futureMonths,
  setOtbData,
  showTitle,
  getAllActions,
}) {

  const { 
    operationId,
    salesCurveData,
    setSalesCurve,
    periodTarget,
    selectedMonths,
    selectedCurveId
  } = useContext(CreatePlanContext);
  const { t } = i18n;

  const navigate = useNavigate();
  const categoryData = useMemo(() => {
    return getCategoryData(futureMonths, allMonthsCategoryData, allMonthsCategoryData[0]?.categoria);
  }, [futureMonths, allMonthsCategoryData]);

  const initialInputs = categoryData.map((entry) => ({
    id: entry.id,
    categoria: entry.categoria,
    mes: entry.mes,
    shareVenda: entry.shareVenda,
    projecaoRecebimento: entry.projecaoRecebimento,
  }));

  const [showInputs, setShowInputs] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [showLastYear, setShowLastYear] = useState(false);
  const [adjustOtb, setAdjustOtb] = useState(false);
  const [saveModal, setSaveModal] = useState("");
  const scrollableDivRef = useRef(null);
  const dataTableRef = useRef(null);

  useEffect(() => {
    if (periodTarget && selectedMonths?.length) {
      setSalesCurve(periodTarget, selectedMonths);
    }
  }, [periodTarget, selectedMonths]);

  useEffect(() => {
    const tableWidth = dataTableRef.current.offsetWidth;
    scrollableDivRef.current.scrollTo({ left: tableWidth });
  }, []);

  const displayPercentageShare = (obj) => {
    return obj.shareVenda
      ? Number(obj.shareVenda * 100).toLocaleString(language, {
          maximumFractionDigits: 1,
        })
      : "0";
  };

  const displaySalesTarget = (categoryData, lastYear = null) => {
    if (lastYear) return displayLastYearValue(categoryData, "vendaRs", (value) => toCurrency(value, 0));
  
    if (!salesCurveData?.curves?.[selectedCurveId]) return toCurrency(0, 0);
  
    const curveData = salesCurveData.curves[selectedCurveId];
    const monthData = curveData.find((monthData) => monthData.month === categoryData.mes);
    const salesValues = curveData.map((item) => item.salesValue);
    const monthIndex = curveData.findIndex((salesTargetMonth) => getDateString(salesTargetMonth.month) === categoryData.mes);
  
    let salesTarget;
  
    if (monthData) {
      salesTarget = Number(monthData.salesValue);
    } else if (monthIndex >= 0 && monthIndex < salesValues.length) {
      salesTarget = salesValues[monthIndex];
    } else {
      salesTarget = Number(categoryData.vendaRs || 0);
    }
  
    return toCurrency(salesTarget, 0);
  };
  
  const displayUnitsSold = (categoryData, lastYear = null) => {
    if (lastYear) return displayLastYearValue(categoryData, "vendaPcs", (value) => Number(value).toLocaleString(language, { maximumFractionDigits: 0 }));
    return categoryData.vendaPcs ? Number(categoryData.vendaPcs).toLocaleString(language, {maximumFractionDigits: 0}) : 0;
  };

  const displayAveragePrice = (categoryData, lastYear = null) => {
    if (lastYear) return displayLastYearValue(categoryData, "precoMedio", (value) => toCurrency(value, 0));
    const precoMedio = categoryData.precoMedio ? Number(categoryData.precoMedio) : 0;
    return precoMedio ? toCurrency(precoMedio, 1) : 0;
  };

  const displayProjectionReceivable = (categoryData) => categoryData.projecaoRecebimento ?? 0;

  const displayFinalStock = (categoryData, lastYear = null) => {
    const finalStock = lastYear ? displayLastYearValue(categoryData, "estoqueFinal", (value) => value) : categoryData.estoqueFinal || 0;
    return Number(finalStock);
  };

  const displayCoverage = (categoryData, lastYear = null) => {
    const coverage = lastYear 
      ? displayLastYearValue(categoryData, "cobertura", (value) => Number(value).toLocaleString(language, { maximumFractionDigits: 0 }))
      : categoryData.cobertura || 0
    return Number(coverage).toLocaleString(language, { maximumFractionDigits: 0 })
  };

  const displayTurnover = (categoryData) => {
    return categoryData.giro
      ? Number(categoryData.giro).toLocaleString(language, { maximumFractionDigits: 2 })
      : "0";
  };

  const checkStockDiscrepancy = (categoryData) => {
    if(!categoryData.otbDisponivel) return "0";
    return Number(categoryData.otbDisponivel).toLocaleString(language, {maximumFractionDigits: 0})
  };

  const handleInputs = (id, property, value) => {
    const newInputs = [...inputs];
    const propertyInputIndex = inputs.findIndex((input) => input.id === id);

    const finalValue =
      property === "shareVenda" && value !== ""
        ? Number((value / 100).toFixed(4))
        : value;

    if (propertyInputIndex !== -1) {
      newInputs[propertyInputIndex] = {
        ...newInputs[propertyInputIndex],
        [property]: finalValue,
      };
    } else {
      newInputs.push({ id, [property]: finalValue });
    }
    setInputs(newInputs);
  };

  const getInputShareValue = (categoryData) => {
    const found = inputs.find((input) => input.mes === categoryData.mes);

    return found?.shareVenda || found?.shareVenda === 0
      ? Number((found.shareVenda * 100).toFixed(2))
      : 0;
  };

  const getInputProjecaoValue = (obj) => {
    const found = inputs.find(
      (input) =>
        input.mes === obj.mes && input.hasOwnProperty("projecaoRecebimento")
    );
    return found.projecaoRecebimento ? found.projecaoRecebimento : "";
  };

  const onCalculateClick = () => {
    calculate();
  };

  const calculate = async () => {
    const simulatedOtb = await simulateOtb(inputs);
    setOtbData(simulatedOtb);
    setShowInputs(false);
    setAdjustOtb(true);
  };

  const gatherInputsAndSimulatedData = () => {
    return categoryData.map((entry) => {
      const inputEntry = inputs.find((input) => input.id === entry.id);
      return { ...entry, ...inputEntry };
    });
  };

  const onSaveClick = async () => {
    const token = localStorage.getItem("token");
    const dataToSave = gatherInputsAndSimulatedData();
    const result = await updateOtb(token, dataToSave);

    if (result === "Created") {
      setSaveModal(t("successAdjustPlan"));
    } else {
      setSaveModal(t("errorAdjustPlan"));
    }
  };

  const defineSimulateButton = () => {
    if (operationId === false) {
      return t("simulate");
    } else if (operationId === "completed") {
      return t("refreshToSimulate");
    } else {
      return (
        <S.SimulateButtonContent>
          <p>{t("loadingSimulation")}</p>
          <Loading small withOverlay={false} />
        </S.SimulateButtonContent>
      );
    }
  };

  const defineSimulationDisabled = () => {
    return operationId !== false && operationId !== "completed";
  };

  const onSimulationClick = () => {
    if (operationId === false) {
      setShowInputs(!showInputs);
    } else if (operationId === "completed") {
      navigate(0);
    }
  };

  return (
      <S.Container>
        <div className="scrollable-div">
          <S.UpperBox>
            <S.ToggleBox negativeMargin={!showTitle}>
              <Toggle
                labelA={t("compareLY")}
                labelB={t("compareLY")}
                className="toggle"
                size="sm"
                toggled={showLastYear}
                onToggle={() => setShowLastYear(!showLastYear)}
              />
            </S.ToggleBox>

            {showTitle && (
              <S.Title>
                <p className="subtitle">{t("category")}</p>
                <p>{defineCategoryName(allMonthsCategoryData[0].categoria)}</p>
              </S.Title>
            )}
          </S.UpperBox>

          <S.TablesBox>
            <S.Table paddingBottom="10px">
              <thead>
                <tr>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{t("idealStock")}</th>
                </tr>
                <tr>
                  <th className="otb-cell">{t("stockExcessOrLack")}</th>
                </tr>
                <tr className="white start-group">
                  <th>{t("salesShare")}</th>
                </tr>
                <tr>
                  <th>{t("salesTarget")}</th>
                </tr>
                {showLastYear && (
                  <tr className="light-green">
                    <th>{t("currencySales") + " " + t("LY")}</th>
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                )}
                <tr className="white">
                  <th>{t("unitSales")}</th>
                </tr>
                {showLastYear && (
                  <tr className="light-green">
                    <th>{t("unitSales") + " " + t("LY")}</th>
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                )}
                <tr className="white">
                  <th>{t("averagePrice")}</th>
                </tr>
                {showLastYear && (
                  <tr className="light-green">
                    <th>{t("averagePrice") + " " + t("LY")}</th>
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                )}

                <tr className="start-group">
                  <th>{t("inventoryPortfolio")}</th>
                </tr>
                <tr className="white">
                  <th>{t("receiptProjection")}</th>
                </tr>
                <tr>
                  <th>{t("finalMonthStock")}</th>
                </tr>
                {showLastYear && (
                  <tr className="light-green">
                    <th>{t("unitStock") + " " + t("LY")}</th>
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    <th>{t("growthPercent")}</th>
                  </tr>
                )}

                <tr className="start-group">
                  <th>{t("coverage")}</th>
                </tr>
                {showLastYear && (
                  <tr className="light-green">
                    <th>{t("coverage") + " " + t("LY")}</th>
                  </tr>
                )}
                <tr className="white">
                  <th>{t("stockTurnover")}</th>
                </tr>
              </tbody>
            </S.Table>

            <S.BodyTable className="scrollable-div" ref={scrollableDivRef}>
              <S.Table ref={dataTableRef}>
              <thead>
                <tr>
                  {categoryData.map((item) => (
                    <th key={item.mes}>{getMonthName(item.mes)}</th>
                  ))}
                </tr>
              </thead>

                <tbody>
                  {/* Estoque ideal */}
                  <tr>
                    {categoryData.map((item) => (
                      <td key={item.id}>{item.estoqueIdeal ?? 0}</td>
                    ))}
                  </tr>

                  {/* Excesso/falta */}
                  <tr>
                    {categoryData.map((item) => (
                      <td key={item.id}>
                        {checkStockDiscrepancy(item) > 0 && (
                          <S.Tag isOver>
                            <div>{t("lack")}</div>- {Math.abs(checkStockDiscrepancy(item))}
                          </S.Tag>
                        )}
                        {checkStockDiscrepancy(item) < 0 && (
                          <S.Tag>
                            <div>{t("excess")}</div>
                            {Math.abs(checkStockDiscrepancy(item))}
                          </S.Tag>
                        )}
                        {checkStockDiscrepancy(item) === 0 && (
                          <S.Tag>
                            <div>Estoque em dia</div>
                          </S.Tag>
                        )}
                      </td>
                    ))}
                  </tr>

                  {/* ShareVenda */}
                  <tr className="white start-group">
                    {inputs.map((item) => (
                      <td key={item.id}>
                        {showInputs && futureMonths.includes(categoryData.mes) ? (
                          <S.Input
                            type="number"
                            onChange={(e) => handleInputs(item.id, "shareVenda", e.target.value)}
                            value={getInputShareValue(item)}
                          />
                        ) : (
                          displayPercentageShare(item)
                        )}
                        {`${" %"}`}
                      </td>
                    ))}
                  </tr>

                  {/* Meta de venda */}
                  <tr>
                    {categoryData.map((item) => (
                      <td key={item.id}>{displaySalesTarget(item)}</td>
                    ))}
                  </tr> 

                  {/* Venda R$ LY */}
                    {showLastYear && (
                      <tr className="light-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>{displaySalesTarget(item, "lastYear")}</td>
                        ))}
                      </tr>
                    )}

                    {showLastYear && (
                      <tr className="lighter-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>
                            {growthPercent(displaySalesTarget(item), displaySalesTarget(item, "lastYear"))}
                          </td>
                        ))}
                      </tr>
                    )}

                  {/* Venda peças */}
                    <tr className="white">
                      {categoryData.map((item) => (
                        <td key={item.id}>{displayUnitsSold(item)}</td>
                    ))}
                  </tr> 

                  {/* Venda em peças LY */}
                    {showLastYear && (
                      <tr className="light-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>{displayUnitsSold(item, "lastYear")}</td>
                        ))}
                      </tr>
                    )}

                    {showLastYear && (
                      <tr className="lighter-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>
                            {growthPercent(displayUnitsSold(item), displayUnitsSold(item, "lastYear"))}
                          </td>
                        ))}
                      </tr>
                    )}

                  {/* Preço Medio */}
                  <tr className="white">
                    {categoryData.map((item) => (
                      <td key={item.id}>{displayAveragePrice(item)}</td>
                    ))}
                  </tr>

                  {/* Preço Medio LY */}
                    {showLastYear && (
                      <tr className="light-green">
                        {categoryData.map((item) => (
                          <td className="light-green" key={item.id}>
                            {displayAveragePrice(item, "lastYear")}
                          </td>
                        ))}
                      </tr>
                    )}

                    {showLastYear && (
                      <tr className="lighter-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>
                            {growthPercent(displayAveragePrice(item), displayAveragePrice(item, "lastYear"))}
                          </td>
                        ))}
                      </tr>
                    )}

                  {/* Carteira */}
                    <tr className="start-group">
                      {categoryData.map((item) => (
                        <td key={item.id}>{item.carteira ? item.carteira : "0"}</td>
                    ))}
                  </tr>

                  {/* Projeção recebimento */}
                    <tr className="white">
                      {inputs.map((item) => (
                        <td key={item.id}>
                          {showInputs && futureMonths.includes(categoryData.mes) ? (
                            <S.Input
                              type="number"
                              onChange={(e) => handleInputs(item.id, "projecaoRecebimento", e.target.value)}
                              value={getInputProjecaoValue(item)}
                            />
                          ) : (
                            displayProjectionReceivable(item)
                          )}
                        </td>
                      ))}
                    </tr> 

                  {/* Estoque final */}
                    <tr>
                      {categoryData.map((item) => (
                        <td key={item.id}>
                          {displayFinalStock(item)}
                        </td>
                      ))}
                    </tr>

                  {/* Estoque em peças LY */}
                    {showLastYear && (
                      <tr className="light-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>
                            {displayFinalStock(item)}
                          </td>
                        ))}
                      </tr>
                    )}

                    {showLastYear && (
                      <tr className="lighter-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>
                            {growthPercent(displayFinalStock(item), displayFinalStock(item, "lastYear"))}
                          </td>
                        ))}
                      </tr>
                    )} 

                  {/* Cobertura */}
                    <tr className="start-group">
                      {categoryData.map((item) => (
                        <td key={item.id}>{displayCoverage(item)}</td>
                      ))}
                    </tr> 

                  {/* Cobertura LY */}
                    {showLastYear && (
                      <tr className="light-green">
                        {categoryData.map((item) => (
                          <td key={item.id}>{displayCoverage(item, "lastYear")}</td>
                        ))}
                      </tr>
                    )} 
                    
                  {/* Giro */}
                    <tr className="white">
                      {categoryData.map((item) => (
                        <td key={item.id}>{displayTurnover(item)}</td>
                      ))}
                    </tr> 

                </tbody>
              </S.Table>
            </S.BodyTable>
          </S.TablesBox>

          <S.ButtonGroup>
            {!showInputs && (
              <S.Button
                green
                renderIcon={Edit}
                onClick={onSimulationClick}
                disabled={defineSimulationDisabled()}
              >
                {defineSimulateButton()}
              </S.Button>
            )}
            {!showInputs && adjustOtb ? (
              <S.Button
                green
                renderIcon={Save}
                tabIndex={-1}
                disabled={!adjustOtb}
                onClick={onSaveClick}
              >
                {t("saveValues")}
              </S.Button>
            ) : (
              <></>
            )}
            {showInputs && (
              <>
                <S.Button
                  renderIcon={Undo}
                  onClick={() => {
                    setInputs(initialInputs);
                    setShowInputs(false);
                  }}
                  green
                >
                  {t("toOriginalValues")}
                </S.Button>
                <S.Button
                  green
                  renderIcon={Calculator}
                  onClick={onCalculateClick}
                  disabled={!showInputs}
                >
                  {t("calculate")}
                </S.Button>
              </>
            )}
          </S.ButtonGroup>
        </div>

        {saveModal && (
          <Modal
            open={saveModal}
            onRequestClose={() => {
              if (getAllActions) {
                getAllActions();
              }
              setSaveModal("");
            }}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                e.preventDefault();
              }
            }}
            size="xs"
            passiveModal
          >
            <S.SaveModalContainer>
              <p>{saveModal}</p>
            </S.SaveModalContainer>
            <S.Button green style={{ width: "100%", marginBottom: "10px" }}>
              Ok!
            </S.Button>
          </Modal>
        )}
      </S.Container>
  );
}

PlanModal.propTypes = {
  allMonthsCategoryData: PropTypes.arrayOf(PropTypes.shape({
    carteira: PropTypes.number,
    categoria: PropTypes.string,
    cobertura: PropTypes.number,
    createdAt: PropTypes.string,
    estoqueFinal: PropTypes.number,
    estoqueIdeal: PropTypes.number,
    giro: PropTypes.string,
    id: PropTypes.number,
    mes: PropTypes.string,
    perdaPotencial: PropTypes.string,
    precoMedio: PropTypes.string,
    projecaoRecebimento: PropTypes.any,
    saldoEstoque: PropTypes.any,
    shareVenda: PropTypes.string,
    updatedAt: PropTypes.string,
    vendaPcs: PropTypes.number,
    vendaRs: PropTypes.string
  })),
  futureMonths: PropTypes.arrayOf(PropTypes.string),
  getAllActions: PropTypes.func,
  setOtbData: PropTypes.func,
  showTitle: PropTypes.bool
}