import React, { useState, useContext } from "react";
import { Column, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { CheckmarkFilled, ProgressBarRound, } from "@carbon/icons-react";
import { i18n } from "../../translate/i18n";

import * as S from "./CreatePlan.style";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import CreateSeasonStep from "./CreateSeasonStep/CreateSeasonStep";
import SalesChannelStep from "./SalesChannelStep/SalesChannelStep";
import LeadTimeStep from "./LeadTimeStep/LeadTimeStep";
import CommercialDaysStep from "./CommercialDaysStep/CommercialDaysStep";
import SalesGraphStep from "./SalesGraphStep/SalesGraphStep";
import SummaryModal from "./SummaryModal/SummaryModal";
import Onboarding from "./Onboarding/Onboarding";
import { CreatePlanContext } from "../../contexts/CreatePlanContext";

export default function CreatePlan() {
  const { t } = i18n;
  const { isLoading } = useContext(CreatePlanContext);
  const [targetStep, setTargetStep] = useState(true);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [navigation, setNavigation] = useState([
    {
      title: t("planningPeriod"),
      completed: false,
      disabled: false,
    },
    {
      title: t("salesChannels"),
      completed: false,
      disabled: true,
    },
    {
      title: t("leadTime"),
      completed: false,
      disabled: true,
    },
    {
      title: t("commercialDates"),
      completed: false,
      disabled: true,
    },
    {
      title: t("salesCurve"),
      completed: false,
      disabled: true,
    },
  ]);

  const backStep = (index) => {
    setSelectedIndex(index - 1);
  };

  const completeStep = (index) => {
    setSelectedIndex(index + 1);
    setNavigation((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, completed: true };
        }
        if (i === index + 1) {
          return { ...item, disabled: false };
        }
        return item;
      })
    );
  };

  if (isLoading) {
    return (
      <S.Loading>
        <ProgressBarRound className="icon-spin" size={60} color="#41a05f" />
        <span>{t("loading")}...</span>
      </S.Loading>
    );
  }

  return (
    <>
      {targetStep && <Onboarding setTargetStep={setTargetStep} />}
      {!targetStep && (
        <LayoutWithMenu isCenter={false}>
          <Column lg={16} md={8} sm={4}>
            <S.Section>
              <S.Title>
                <h3>{t("createPlan")}</h3>
              </S.Title>

              <Tabs selectedIndex={selectedIndex}>
                <TabList fullWidth style={{ flex: 1 }}>
                  {navigation.map((item, index) => (
                    <S.Tab
                      key={index}
                      completed={item.completed}
                      renderIcon={item.completed ? CheckmarkFilled : null}
                      disabled={item.disabled}
                    >
                      {item.title}
                    </S.Tab>
                  ))}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <CreateSeasonStep onCompleteStep={completeStep} />
                  </TabPanel>

                  <TabPanel>
                    <SalesChannelStep
                      onCompleteStep={completeStep}
                      onBackStep={backStep}
                    />
                  </TabPanel>

                  <TabPanel>
                    <LeadTimeStep
                      onCompleteStep={completeStep}
                      onBackStep={backStep}
                    />
                  </TabPanel>

                  <TabPanel>
                    <CommercialDaysStep
                      onCompleteStep={completeStep}
                      onBackStep={backStep}
                    />
                  </TabPanel>

                  <TabPanel>
                    <SalesGraphStep
                      onCompleteStep={() => setShowSummaryModal(true)}
                      onBackStep={backStep}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </S.Section>
          </Column>

          <SummaryModal
            showSummaryModal={showSummaryModal}
            setShowSummaryModal={setShowSummaryModal}
          />
        </LayoutWithMenu>
      )}
    </>
  );
}
