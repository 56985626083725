import { useEffect, useState } from 'react';
import { TenantContext } from '../contexts/TenantContext';

export function useTenant() {
  const [tenant, setTenant] = useState(() => TenantContext.getTenant());

  useEffect(() => {
    const handleStorageChange = () => {
      setTenant(TenantContext.getTenant());
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return tenant;
}
