import { createContext, useState, useEffect } from "react";
import { i18n } from "../translate/i18n";

import { getProducts } from "../services/Products.service";
import { getAllCategories } from "../services/ProductCategoriesService";
import { generateSalesCurve } from '../services/Otb.service';
import PropTypes from 'prop-types';

export const CreatePlanContext = createContext();

export default function CreatePlanProvider({ children }) {
  const { t } = i18n;
  const languageCode = localStorage.getItem("i18nextLng").slice(0, 2) || "en";

  const [isLoading, setIsLoading] = useState(true);
  const [yearTarget, setYearTarget] = useState("");
  const [inflation, setInflation] = useState("");
  const [lastYearRevenue, setLastYearRevenue] = useState("");
  const [periodTarget, setPeriodTarget] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [channels, setChannels] = useState({
    fisica: 0,
    digital: 0,
    atacado: 0,
  });
  const [leadTime, setLeadTime] = useState([]);
  const [commercialDays, setCommercialDays] = useState(
    languageCode === "pt"
      ? [
          { day: 9, month: 3, name: "Páscoa" },
          { day: 15, month: 2, name: "Dia do Consumidor" },
          { day: 12, month: 5, name: "Dia dos Namorados" },
          { day: 14, month: 4, name: "Dia das Mães" },
          { day: 11, month: 7, name: "Dia dos Pais" },
          { day: 29, month: 10, name: "Black friday" },
        ]
      : [
          { day: 20, month: 4, name: "Easter" },
          { day: 14, month: 2, name: "Valentine's Day" },
          { day: 11, month: 5, name: "Mother's Day" },
          { day: 14, month: 6, name: "Father's Day" },
          { day: 28, month: 11, name: "Black Friday" },
        ]
  );
  const [personalizedDates, setPersonalizedDates] = useState([]);
  const [selectedCurveId, setSelectedCurveId] = useState(0);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [otbData, setOtbData] = useState([]);
  const [operationId, setOperationId] = useState(false);
  const [actions, setActions] = useState({});
  const [salesCurveData, setSalesCurveData] = useState(null);

  const useCategories = categories.length;

  const today = new Date();
  const currentYear = today.getFullYear();

  const allDates = commercialDays.concat(personalizedDates);
  const selectedDates = allDates.filter((date) => date.selected);

  const curves = [
    { id: 0, name: t("accessories") },
    { id: 1, name: t("footwear") },
    { id: 2, name: t("women") },
    { id: 3, name: t("children") },
    { id: 4, name: t("men") },
  ];

  const selectedCurve = curves.find(
    (curve) => curve.id === selectedCurveId
  )?.name;

  const getMonthAbbreviations = () => {
    const locale = localStorage.getItem("i18nextLng") || "pt";
    return Array.from({ length: 12 }, (_, index) =>
      new Date(0, index).toLocaleString(locale, { month: "short" })
    );
  };

  const monthAbbreviations = getMonthAbbreviations();

  const writeMonthLabel = (date) => {
    const monthAbbreviations = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const [month] = date.split("-");
    const monthIndex = parseInt(month, 10) - 1;
    const monthAbbreviation = monthAbbreviations[monthIndex];
    const yearSuffix = date.slice(-2);
  
    return `${monthAbbreviation}/${yearSuffix}`;
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const fetchedProducts = await getProducts("15");
      setProducts(fetchedProducts);

      const fetchedCategories = await getAllCategories();
      setCategories(fetchedCategories);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInflation = async () => {
    try {
      const fetchedInflation = 4.71;
      setInflation(fetchedInflation);
    } catch (error) {
      console.log(error);
    }
  };

  const setSalesCurve = async (periodTarget, selectedMonths) => {
    const formattedValue = parseFloat(periodTarget.replace(/\./g, '').replace(',', '.'));
    try {
      const result = await generateSalesCurve(formattedValue, selectedMonths);
      setSalesCurveData(result);
      return result;
    } catch(err) {
      console.error("Erro ao gerar curva de vendas:", err);
      setSalesCurveData(null);
      return null;
    }
  }

  useEffect(() => {
    fetchProducts();
    fetchInflation();
  }, []);

  return (
    <CreatePlanContext.Provider
      value={{
        currentYear,
        inflation,
        setInflation,
        lastYearRevenue,
        setLastYearRevenue,
        yearTarget,
        setYearTarget,
        periodTarget,
        setPeriodTarget,
        selectedMonths,
        setSelectedMonths,
        channels,
        setChannels,
        leadTime,
        setLeadTime,
        useCategories,
        isLoading,
        products,
        categories,
        setCategories,
        commercialDays,
        setCommercialDays,
        personalizedDates,
        setPersonalizedDates,
        selectedDates,
        selectedCurveId,
        setSelectedCurveId,
        selectedCurve,
        writeMonthLabel,
        monthAbbreviations,
        otbData,
        setOtbData,
        operationId,
        setOperationId,
        actions,
        setActions,
        salesCurveData,
        setSalesCurve,
      }}
    >
      {children}
    </CreatePlanContext.Provider>
  );
}

CreatePlanProvider.propTypes = {
  children: PropTypes.node.isRequired
};