import React, { useState } from "react";
import { Modal } from "@carbon/react";
import {
  ArrowDown,
  CheckmarkFilled,
  ChevronRight,
  CircleDash,
} from "@carbon/icons-react";
import { i18n } from "../../../../translate/i18n";

import * as S from "./ItemView.style";

import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault";
import StockTag from "../StockTag/StockTag";
import PlanModal from "./PlanModal/PlanModal";
import { defineCategoryName } from "../../utils/otb.components.utils";
import { language } from "../../../../consts/language";
import PropTypes from 'prop-types';

export default function ItemView({
  categoryData,
  setOtbData,
  futureMonths,
  actions,
  getAllActions,
}) {
  const { t } = i18n;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const status = [
    { titulo: t("actionsAnalysis"), completa: true },
    { titulo: t("planAdjustment"), completa: false },
    { titulo: t("adjustmentAlignment"), completa: false },
  ];

  const planMonthsObjs = categoryData.filter((entry) =>
    futureMonths.includes(entry.mes)
  );

  const otbDisponivel = planMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.otbDisponivel),
    0
  );

  return (
    <>
      <div className="item">
        <S.Section>
          <S.TitleSection>
            <span className="titulo">{t("category")}</span>
            <span className="nome">
              {defineCategoryName(categoryData[0].categoria)}
            </span>
          </S.TitleSection>
        </S.Section>
        <S.Section>
          <StockTag isOver={otbDisponivel < 0} />
          <span className="numero" style={{ marginTop: "5px" }}>
            {Math.abs(otbDisponivel).toLocaleString(language, {
              maximumFractionDigits: 0,
            })}
          </span>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">{t("suggestedActions")}</span>
            <span className="numero">
              {actions.length.toLocaleString(language)}
            </span>
          </S.NumberSection>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">{t("actionsROI")}</span>
            <span className="numero">0</span>
          </S.NumberSection>
        </S.Section>
        <div>
          <S.StatusSection>
            <span className="titulo-status">{t("status")}</span>
            {status.map((status) => (
              <span className="status" key={status.titulo}>
                <span>{status.titulo}</span>
                {status.completa ? (
                  <CheckmarkFilled color="green" />
                ) : (
                  <CircleDash />
                )}
              </span>
            ))}
          </S.StatusSection>
        </div>
        <button className="openBtn" onClick={() => setOpen(!open)}>
          <ArrowDown />
        </button>
      </div>
      <div className={open ? "painel show" : "painel"}>
        <S.Title>
          <S.CategoryTitle>{t("category")}</S.CategoryTitle>
          <S.ItemTitle>
            {defineCategoryName(categoryData[0].categoria)}
          </S.ItemTitle>
        </S.Title>
        <S.Content>
          <ul>
            {actions.map((action) => (
              <S.ListItem key={actions.category}>{action}</S.ListItem>
            ))}
          </ul>
          <ButtonDefault
            className="ajustarPlano"
            renderIcon={() => <ChevronRight />}
            onClick={() => setOpenModal(true)}
          >
            {t("adjustPlan")}
          </ButtonDefault>
        </S.Content>
      </div>
      <S.Container>
        <Modal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
          size="lg"
          passiveModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
            }
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h4>{t("adjustPlan")}</h4>
            <button
              type="button"
              onClick={() => setOpenModal(false)}
              aria-label="Close"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "1.5rem",
                lineHeight: "1",
              }}
            >
              &times;
            </button>
          </div>
          {futureMonths.length ? (
            <PlanModal
              allMonthsCategoryData={categoryData}
              futureMonths={futureMonths}
              setOtbData={setOtbData}
              showTitle
              getAllActions={getAllActions}
            />
          ) : null}
        </Modal>
      </S.Container>
    </>
  );
}

ItemView.propTypes = {
  categoryData: PropTypes.arrayOf(PropTypes.shape({
    carteira: PropTypes.number,
    categoria: PropTypes.string,
    cobertura: PropTypes.number,
    createdAt: PropTypes.string,
    estoqueFinal: PropTypes.number,
    estoqueIdeal: PropTypes.number,
    giro: PropTypes.string,
    id: PropTypes.number,
    mes: PropTypes.string,
    perdaPotencial: PropTypes.string,
    precoMedio: PropTypes.string,
    projecaoRecebimento: PropTypes.any,
    saldoEstoque: PropTypes.any,
    shareVenda: PropTypes.string,
    updatedAt: PropTypes.string,
    vendaPcs: PropTypes.number,
    vendaRs: PropTypes.string
  })).isRequired,
  futureMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  getAllActions: PropTypes.func.isRequired,
  setOtbData: PropTypes.func.isRequired,
}