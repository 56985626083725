import React from "react";
import { i18n } from "../../../../translate/i18n";
import PropTypes from 'prop-types';
import * as S from "./StockTag.style";

export default function StockTag({ isOver }) {
  const { t } = i18n;

  return (
    <S.Tag backgroundColor={isOver ? "green" : "red"}>
      {isOver ? t("stockExcess") : t("stockLack")}
    </S.Tag>
  );
}

StockTag.propTypes = {
  isOver: PropTypes.bool
}