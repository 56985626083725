import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Flash,
  HealthCross,
  Rocket,
  FlashFilled,
  Box,
} from "@carbon/icons-react";
import { i18n } from "../../translate/i18n";

import * as S from "./table.style";

import ModalDetalhe from "../ModelDetalhe/ModalDetalhe";
import { BoxFilled, HealthCrossFilled, RocketFilled } from "../LocalIcons";

import { getProducts } from "../../services/Products.service";
import { language } from "../../consts/language";

const { t } = i18n;

const listHeader = (metricas) => [
  { title: "#", MultLines: false },
  { title: t("sku"), MultLines: false },
  {
    title: t("salesPerformanceAbbreviation"),
    MultLines: true,
    percent: metricas?.cluster?.percentage,
    icon: "Rocket",
  },
  {
    title: t("salesSpeedAbbreviation"),
    MultLines: true,
    percent: metricas?.sellThrough?.percentage,
    icon: "Flash",
  },
  {
    title: t("stockHealth"),
    MultLines: true,
    percent: metricas?.giro?.percentage,
    icon: "HealthCross",
  },
  {
    title: t("stockCoverageAbbreviation"),
    MultLines: true,
    percent: metricas?.age?.percentage,
    icon: "Box",
  },
  { title: t("revenueValue"), MultLines: false },
  { title: t("revenueShare"), MultLines: false },
  { title: t("revenueGrowthLastYear"), MultLines: false },
  { title: t("averageSellingPrice"), MultLines: false },
  { title: t("salesValueShare"), MultLines: false },
  { title: t("stockUnits"), MultLines: false },
  { title: t("stockValue"), MultLines: false },
  { title: t("coverageDays"), MultLines: false },
];

function truncateString(str, maxLength = 10) {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

function Table({ type, metricas, temporada }) {
  const [startModal, setStartModal] = useState(false);
  const [valueSendModal, setValueSendModal] = useState(null);
  const [data, setData] = useState([]);
  const accordingStatus = (status, Icons, FilledIcons) => {
    function capitalizeFirstLetter(word) {
      const translate = t(word);
      return translate.charAt(0).toUpperCase() + translate.slice(1).toLowerCase();
    }

    switch (status) {
      case "excelente": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
          </div>
        );
      }
      case "ótimo": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <Icons />
          </div>
        );
      }
      case "regular": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <Icons />
            <Icons />
          </div>
        );
      }
      case "crítico": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <Icons />
            <Icons />
            <Icons />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  const buildColumnIcons = (type, value) => {
    if (type === "performance") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, Rocket, RocketFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "velocidade") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, Flash, FlashFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "saude") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, HealthCross, HealthCrossFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "cobertura") {
      return (
        <S.tdMultLine>{accordingStatus(value, Box, BoxFilled)}</S.tdMultLine>
      );
    }
    return null;
  };

  const showRenderIcon = (icon) => {
    switch (icon) {
      case "Rocket": {
        return <Rocket />;
      }
      case "Flash": {
        return <Flash />;
      }
      case "HealthCross": {
        return <HealthCross />;
      }
      case "Box": {
        return <Box />;
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    const getDefaultValues = async () => {
      try {
        const products = await getProducts(temporada);
        setData(products);
      } catch (error) {
        console.log(error.message);
      }
    };
    getDefaultValues();
  }, [temporada]);

  return (
    <S.table>
      <S.theader>
        <S.tr>
          {listHeader(metricas).map((item, index) => {
            if (item.title === "#") {
              return (
                <S.th key={item.title} size={48}>
                  {item.title}
                </S.th>
              );
            }

            if (item.MultLines) {
              return (
                <S.thTwoLine key={item.title}>
                  <span>{item.title}</span>
                  <div className="headerTwoLinePercent">
                    <span>{item.percent}%</span> {showRenderIcon(item.icon)}
                  </div>
                </S.thTwoLine>
              );
            }
            return <S.th key={item.title}>{item.title}</S.th>;
          })}
        </S.tr>
      </S.theader>

      <S.tbody>
        {data.map(
          ({
            id,
            sku,
            cor,
            produto,
            cluster,
            idade,
            st,
            giro,
            receita,
            shareReceita,
            crescReceita,
            precoMedioVenda,
            shareValorVenda,
            estoquePcs,
            estoqueRs,
            cobertura,
            feedbackMessage,
          }) => (
            <S.tr
              key={id}
              color
              onClick={() => {
                setStartModal(true);
                setValueSendModal({
                  cor,
                  produto,
                  sku,
                  cluster,
                  st,
                  giro,
                  idade,
                  receita,
                  shareReceita,
                  crescReceita,
                  precoMedioVenda,
                  shareValorVenda,
                  estoquePcs,
                  estoqueRs,
                  cobertura,
                  feedbackMessage,
                });
              }}
            >
              <S.td>{id}</S.td>
              <S.td>
                <S.tdMultLine>
                  <div className="lineColor">{sku}</div>
                  <strong>
                    <div>{truncateString(produto)}</div>
                  </strong>
                  <div>{cor}</div>
                </S.tdMultLine>
              </S.td>
              <S.td>
                {buildColumnIcons("performance", cluster.toLowerCase())}
              </S.td>
              <S.td>{buildColumnIcons("velocidade", st.toLowerCase())}</S.td>
              <S.td>{buildColumnIcons("saude", giro.toLowerCase())}</S.td>
              <S.td>{buildColumnIcons("cobertura", idade.toLowerCase())}</S.td>
              <S.td>
                {receita.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </S.td>
              <S.td>{shareReceita}</S.td>
              <S.td>{crescReceita || 0}</S.td>
              <S.td>
                {precoMedioVenda.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </S.td>
              <S.td>{shareValorVenda}</S.td>
              <S.td>{estoquePcs}</S.td>
              <S.td>
                {estoqueRs.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </S.td>
              <S.td>{cobertura}</S.td>
            </S.tr>
          )
        )}
      </S.tbody>

      {valueSendModal && (
        <ModalDetalhe
          open={startModal}
          item={valueSendModal}
          setShow={setStartModal}
        />
      )}
    </S.table>
  );
}

Table.propTypes = {
  metricas: PropTypes.shape({
    cluster: PropTypes.shape({
      classify: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
    }),
    sellThrough: PropTypes.shape({
      classify: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
    }),
    age: PropTypes.shape({
      classify: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
    }),
    giro: PropTypes.shape({
      classify: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
    }),
  }),
  temporada: PropTypes.string,
  type: PropTypes.string,
};

export default Table;
