import React, { useContext, useEffect, useState } from "react";
import { Column, Grid } from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import moment from "moment";
import "moment/locale/pt-br";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./CreateSeasonStep.style";

import Chart from "../../../assets/icons/Chart--bar.svg";
import TargetSection from "./TargetSection/TargetSection";
import PeriodSection from "./PeriodSection/PeriodSection";
import SelectedPeriodSection from "./SelectedPeriodSection/SelectedPeriodSection";
import CustomPeriodSelection from "./CustomPeriodSelection/CustomPeriodSelection";
import { language } from "../../../consts/language";
import PropTypes from 'prop-types';

export default function CreateSeasonStep({ onCompleteStep }) {
  const { t } = i18n;
  const { selectedMonths, setSelectedMonths, periodTarget, setSalesCurve } = useContext(CreatePlanContext);
  const [period, setPeriod] = useState();
  const [dates, setDates] = useState({});

  const months = Array.from({ length: 36 }).map((entry, i) => {
    const currentDate = moment().subtract(moment().month() - i, "months");
    return {
      id: currentDate.month(),
      month: currentDate.locale(language).format("MMM"),
      year: currentDate.year(),
    };
  });

  function groupBy(array, callback) {
    return array.reduce((grouped, item) => {
      const key = callback(item);
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(item);
      return grouped;
    }, {});
  }

  const monthsByYear = groupBy(months, (month) => { return month.year });

  const periodOptions = {
    next6months: t("next6Months"),
    eoy: t("untilTheEndOfYear"),
    next12months: t("next12Months"),
    eony: t("untilEndNextYear"),
    custom: t("custom"),
  };

  const defineCustomMonths = () => {
    let newSelectedMonths = [];

    if (dates.startDate && dates.endDate) {
      const start = moment(dates.startDate);
      const end = moment(dates.endDate);

      const monthsBetween = end.diff(start, "months");

      newSelectedMonths = Array.from({ length: monthsBetween + 1 }).map(
        (entry, i) => {
          const currentMonth = start.clone().add(i, "months");
          return `${currentMonth.month()}-${currentMonth.year()}`;
        }
      );
    }

    setSelectedMonths(newSelectedMonths);
  };

  useEffect(() => {
    if (!period) return;
    if (period !== "custom") {
      switch (period) {
        case "next6months":
          setSelectedMonths(
            Array.from({ length: 6 }).map((entry, i) => {
              const date = moment().add(i, "months");
              return `${date.month() + 1}-${date.year()}`; // Ajusta o mês para 1 a 12
            })
          );
          break;
        case "next12months":
          setSelectedMonths(
            Array.from({ length: 12 }).map((entry, i) => {
              const date = moment().add(i, "months");
              return `${date.month() + 1}-${date.year()}`; // Ajusta o mês para 1 a 12
            })
          );
          break;
        case "eoy":
          setSelectedMonths(
            Array.from({
              length: moment().endOf("year").month() - moment().month() + 1,
            }).map((entry, i) => {
              const date = moment().add(i, "months");
              return `${date.month() + 1}-${date.year()}`; // Ajusta o mês para 1 a 12
            })
          );
          break;
        case "eony":
          setSelectedMonths(
            Array.from({
              length:
                moment().endOf("year").month() + 12 - moment().month() + 1,
            }).map((entry, i) => {
              const date = moment().add(i, "months");
              return `${date.month() + 1}-${date.year()}`; // Ajusta o mês para 1 a 12
            })
          );
          break;
        default:
          break;
      }
    } else {
      defineCustomMonths();
    }
  }, [period, dates]);  

  return (
    <Grid style={{ marginTop: 40 }} fullWidth>
      <Column lg={6} md={16} sm={16}>
        <S.Title>
          <img src={Chart} alt={t("planningPeriod")} />
          <h4>{t("planningPeriod")}</h4>
        </S.Title>
        <S.Text>{t("planningExplanationText")}</S.Text>
      </Column>
      
      <Column
        lg={10}
        md={16}
        sm={16}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 24px",
        }}
      >
        <S.Text largerPadding>{t("selectPeriodPlanning")}</S.Text>

        <S.SessionsContainer>
          <TargetSection />

          <PeriodSection
            periodOptions={periodOptions}
            period={period}
            setPeriod={setPeriod}
          />

          <SelectedPeriodSection
            monthsByYear={monthsByYear}
            selectedMonths={selectedMonths}
          />
        </S.SessionsContainer>

        {period === "custom" && (
          <CustomPeriodSelection dates={dates} setDates={setDates} />
        )}

        <S.ButtonSet>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              setSalesCurve(periodTarget, selectedMonths);
              onCompleteStep(0);
            }}
            disabled={!periodTarget || !selectedMonths.length}
          >
            {t("next")}
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}

CreateSeasonStep.propTypes = {
  onCompleteStep: PropTypes.func
}