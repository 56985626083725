import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TenantContext } from '../contexts/TenantContext';
import { useTenant } from '../hooks/useTenant';

const AuthContext = createContext(undefined);

export function AuthProvider({ children }) {
  const tenant = useTenant();

  const login = (token, tenant) => {
    TenantContext.setSession(token, tenant);
  };

  const logout = () => {
    TenantContext.clearSession();
  };

  const value = useMemo(
    () => ({
      tenant,
      login,
      logout,
      isAuthenticated: TenantContext.hasSession(),
    }),
    [tenant]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within an AuthProvider');
  return context;
}
