export const TenantContext = {
    setSession(token, tenant) {
      localStorage.setItem('token', token);
      localStorage.setItem('tenant', JSON.stringify(tenant));
    },
  
    getTenant() {
      const tenantStr = localStorage.getItem('tenant');
      return tenantStr ? JSON.parse(tenantStr) : null;
    },
  
    getToken() {
      return localStorage.getItem('token');
    },
  
    clearSession() {
      localStorage.removeItem('token');
      localStorage.removeItem('tenant');
    },
  
    hasSession() {
      return !!this.getToken() && !!this.getTenant();
    }
  };