import axios from "./BaseService";

export const doLogin = async(email, password) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
  return response.data;
}

export const doLogout = async() => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`);
  return response.data;
}
