import React from "react";
import PropTypes from "prop-types";
import { ComposedModal, ModalHeader } from "@carbon/react";
import {
  Flash,
  HealthCross,
  Box,
  Star,
  StarFilled,
  FlashFilled,
} from "@carbon/icons-react";
import { i18n } from "../../translate/i18n";

import * as S from "./modalDetalhe.style";
import { BoxFilled, HealthCrossFilled } from "../LocalIcons";
import { language } from "../../consts/language";

export default function ModalDetalhe({ open, item, setShow }) {
  const { t } = i18n;

  const accordingStatus = (status, Icons, FilledIcons) => {
    function capitalizeFirstLetter(word) {
      const translate = t(word);
      return translate.charAt(0).toUpperCase() + translate.slice(1).toLowerCase();
    }

    switch (status) {
      case "excelente": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(t("excellent"))}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
          </div>
        );
      }
      case "ótimo": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(t("great"))}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <Icons />
          </div>
        );
      }
      case "regular": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(t("regular"))}
            </span>
            <FilledIcons />
            <FilledIcons />
            <Icons />
            <Icons />
          </div>
        );
      }
      case "crítico": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(t("critical"))}
            </span>
            <FilledIcons />
            <Icons />
            <Icons />
            <Icons />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  if (!item)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Não há informações disponíveis sobre este produto.</h2>
      </div>
    );

  const {
    sku,
    cor,
    produto,
    cluster,
    st,
    giro,
    idade,
    receita,
    shareReceita,
    crescReceita,
    precoMedioVenda,
    shareValorVenda,
    estoquePcs,
    estoqueRs,
    cobertura,
    feedbackMessage,
  } = item;

  return (
    <S.content>
      <ComposedModal open={open} onClose={() => setShow(false)}>
        <ModalHeader>
          <S.headerTitle>
            <span className="id">{sku}</span>
            <h3>{produto}</h3>
            <span className="legend">{cor}</span>
          </S.headerTitle>
        </ModalHeader>
        <S.contentModal>
          <h5 className="m-b-5">{t("generalInformation")}</h5>
          <S.contentInputsValues>
            <S.frameInfoAndValue>
              <span>{t("revenueRs")}</span>
              <strong>
                {receita.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>{t("revenueShare")}</span>
              <strong>{shareReceita}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>{t("revenueGrowth")}</span>
              <strong>{crescReceita || 0}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>{t("averageSalesPrice")}</span>
              <strong>
                {precoMedioVenda.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>{t("salesValueShare")}</span>
              <strong>{shareValorVenda}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>{t("stockPcs")}</span>
              <strong>{estoquePcs}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>{t("stockRs")}</span>
              <strong>
                {estoqueRs.toLocaleString(language, {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>{t("coverageDays")}</span>
              <strong>{cobertura || 0}</strong>
            </S.frameInfoAndValue>
          </S.contentInputsValues>
        </S.contentModal>
        <S.contentModal>
          <h5 className="m-b-5">{t("sumplyFactors")}</h5>
          <div>
            <S.contentDialog>
              <div>
                <S.dialog key={1}>
                  <S.dialogTitle>
                    <strong className="title">{t("salesPerformance")}</strong>
                    <div>
                      {accordingStatus(cluster.toLowerCase(), Star, StarFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>

                <S.dialog key={2}>
                  <S.dialogTitle>
                    <strong className="title">{t("salesSpeed")}</strong>
                    <div>
                      {accordingStatus(st.toLowerCase(), Flash, FlashFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>
              </div>
              <div>
                <S.dialog key={3}>
                  <S.dialogTitle>
                    <strong className="title">{t("stockHealth")}</strong>
                    <div>
                      {accordingStatus(
                        giro.toLowerCase(),
                        HealthCross,
                        HealthCrossFilled
                      )}
                    </div>
                  </S.dialogTitle>
                </S.dialog>

                <S.dialog key={4}>
                  <S.dialogTitle>
                    <strong className="title">{t("stockCoverage")}</strong>
                    <div>
                      {accordingStatus(idade.toLowerCase(), Box, BoxFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>
              </div>

              <S.dialogOnlyText key={5}>
                <S.dialogText>{t("noMessage") || feedbackMessage }</S.dialogText>
              </S.dialogOnlyText>
            </S.contentDialog>
          </div>
        </S.contentModal>
      </ComposedModal>
    </S.content>
  );
}

ModalDetalhe.propTypes = {
  item: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
