  import axios from "./BaseService";

  export const getAllCategories = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categoryprodutcs`);
      return response.data;
    } catch(err) {
      if (err.response) {
        throw new Error(
          JSON.stringify({
            error: err.response.statusText,
            status: err.response.status,
          })
        );
      }

      console.error('Erro em get all categories:', err.message);
      throw err;
    }
  }

  export const updateCategories = async(newCategories) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/categoryprodutcs`, newCategories);
      return response.data;
    } catch(err) {
      if (err.response) {
        throw new Error(
          JSON.stringify({
            error: err.response.statusText,
            status: err.response.status,
          })
        );
      }

      console.error('Erro em get all categories:', err.message);
      throw err;
    }
  }
