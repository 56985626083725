import { Loading } from "@carbon/react";
import * as S from "./GeneralView.style";
import PropTypes from 'prop-types';
import ItemView from "./ItemView/ItemView";
import IndexesSection from "./IndexesSection/IndexesSection";

export default function GeneralView({
  otbData,
  setOtbData,
  futureMonths,
  actions,
  getAllActions,
}) {

  const groupByCategory = (data) => {
    return data.reduce((acc, item) => {
      const categoria = item.categoria;
      if (!acc[categoria]) {
        acc[categoria] = [];
      }
      acc[categoria].push(item);
      return acc;
    }, {});
  };

  const getCategoryActions = (categoryArray) => {
    if (!Array.isArray(categoryArray) || !categoryArray.length) {
      return [];
    }
    
    const categoriaName = categoryArray[0].categoria;
    const categoryActions = actions.find(
      (actionObj) => actionObj.category === categoriaName
    );
    
    return actions?.length ? categoryActions.actions : [];
  };

  const groupedData = groupByCategory(otbData);
  
  return futureMonths.length ? (
    <>
      <IndexesSection
        otbData={otbData}
        futureMonths={futureMonths}
        actions={actions}
      />
      <S.Wrapper>
        {Array.isArray(actions) && actions.length > 0 ? (
          <div className="lista">
            {Object.entries(groupedData).map(([_, categoryData], index) => (
              <ItemView
                key={categoryData.id}
                categoryData={categoryData}
                setOtbData={setOtbData}
                futureMonths={futureMonths}
                actions={getCategoryActions(categoryData)}
                getAllActions={getAllActions}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </S.Wrapper>
    </>
  ) : (
    <S.LoadingWrapper>
      <Loading withOverlay={false} />
    </S.LoadingWrapper>
  );
}

GeneralView.propTypes = {
  otbData: PropTypes.arrayOf(PropTypes.shape({
    carteira: PropTypes.number,
    categoria: PropTypes.string,
    cobertura: PropTypes.number,
    createdAt: PropTypes.string,
    estoqueFinal: PropTypes.number,
    estoqueIdeal: PropTypes.number,
    giro: PropTypes.string,
    id: PropTypes.number,
    mes: PropTypes.string,
    perdaPotencial: PropTypes.string,
    precoMedio: PropTypes.string,
    projecaoRecebimento: PropTypes.any,
    saldoEstoque: PropTypes.any,
    shareVenda: PropTypes.string,
    updatedAt: PropTypes.string,
    vendaPcs: PropTypes.number,
    vendaRs: PropTypes.string
  })).isRequired,
  setOtbData: PropTypes.func.isRequired,
  futureMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  getAllActions: PropTypes.func.isRequired,
}