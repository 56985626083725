
export const InputSelectData = () => {
  const ids = [15, 30, 60, 90];

 return ids.map((interval) => {
    return {
      id: interval,
      text: `últimos ${interval} dias`,
    };
  });
}

